<script setup>
import SidenavDropdown from "./SidenavDropdown.vue";
import SidenavItem from "./SidenavItem.vue";
import SidenavCard from "./SidenavCard.vue";
import { useRoute } from "vue-router";
// import { useStore } from "vuex";
import Swal from "sweetalert2";

const dropdownSalesItems = [
  {
    to: "/billing",
    title: "Nuevo comprobante",
  },
  {
    to: "/billing/credit-note",
    title: "Notas de crédito",
  },
  {
    to: "/billing/view",
    title: "Ver todos",
  },
];
const dropdownClientsItems = [
  {
    to: "/client/new",
    title: "Nuevo cliente",
  },
  {
    to: "/client/credits",
    title: "Créditos y deudas",
  },
  {
    to: "/client/view",
    title: "Ver todos",
  },
];
const dropdownProductsItems = [
  {
    to: "/products/new",
    title: "Nuevo producto",
  },
  {
    to: "/products/categories",
    title: "Categorias",
  },
  {
    to: "/products/providers",
    title: "Proveedores",
  },
  {
    to: "/products/view/1",
    title: "Ver todos",
  },
];
const dropdownCashRegisterItems = [
  {
    to: "",
    title: "Apertura de caja",
  },
  {
    to: "",
    title: "Registro de pagos",
  },
  {
    to: "",
    title: "Mostrar lista de pagos",
  },
];
const dropdownStockItems = [
  {
    to: "",
    title: "Inventario",
  },
  {
    to: "",
    title: "Ajustes de stock",
  },
  {
    to: "",
    title: "Ingresos de stock",
  },
  {
    to: "",
    title: "Preparación productos",
  },
  {
    to: "",
    title: "Lista de ajustes",
  },
];
const dropdownUsersItems = [
  {
    to: "/users/new",
    title: "Crear usuarios"
  },
  {
    to: "/users/view",
    title: "Lista de usuarios"
  },
];

const showNewClient = () => {
  return Swal.fire({
    title: "Good job!",
    text: "You clicked the button!",
    icon: "success"
  });
}

const getRoute = () => {
  const route = useRoute();
  const routeArr = route.path.split("/");
  return routeArr[1];
};
</script>
<template>
  <div class="collapse navbar-collapse w-auto h-auto h-100" id="sidenav-collapse-main">
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-item to="/dashboard" :class="getRoute() === 'dashboard' ? 'active' : ''" :navText="'Página principal'">
          <template v-slot:icon>
            <i class="fa-solid fa-house text-secondary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-dropdown idName="sales" :options="dropdownSalesItems" menuName="salesDropDown" textDropDown="Ventas">
          <template v-slot:icon>
            <i class="fa-solid fa-bag-shopping text-secondary text-sm opacity-10"></i>
          </template>
        </sidenav-dropdown>
      </li>

      <li class="nav-item">
        <sidenav-dropdown idName="clients" :options="dropdownClientsItems" menuName="clientDropDown"
          textDropDown="Clientes">
          <template v-slot:icon>
            <i class="fa-solid fa-address-book text-secondary text-sm opacity-10"></i>
          </template>
        </sidenav-dropdown>
      </li>

      <li class="nav-item">
        <sidenav-dropdown idName="products" :options="dropdownProductsItems" menuName="productsDropDown"
          textDropDown="Productos">
          <template v-slot:icon>
            <i class="fa-solid fa-box text-secondary text-sm opacity-10"></i>
          </template>
        </sidenav-dropdown>
      </li>

      <li class="nav-item">
        <sidenav-dropdown idName="stock" :options="dropdownStockItems" menuName="stockDropDown" textDropDown="Stock">
          <template v-slot:icon>
            <i class="fa-solid fa-boxes-stacked text-secondary text-sm opacity-10"></i>
          </template>
        </sidenav-dropdown>
      </li>

      <li class="nav-item">
        <sidenav-dropdown idName="cashRegister" :options="dropdownCashRegisterItems" menuName="cashRegisterDropDown"
          textDropDown="Caja" badgeText="Abierta">
          <template v-slot:icon>
            <i class="fa-solid fa-cash-register text-secondary text-sm opacity-10"></i>
          </template>
        </sidenav-dropdown>
      </li>

      <li class="nav-item">
        <sidenav-item to="/reports" :class="getRoute() === 'reports' ? 'active' : ''" navText="Reportes">
          <template v-slot:icon>
            <i class="fa-solid fa-chart-pie text-secondary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="mt-3 nav-item">
        <h6 class="text-xs ps-4 text-uppercase text-warning font-weight-bolder opacity-6 ms-2">
          Utilidades
        </h6>
      </li>

      <li class="nav-item">
        <sidenav-item to="/tags" :class="getRoute() === 'tags' ? 'active' : ''" navText="Etiquetas">
          <template v-slot:icon>
            <i class="fa-solid fa-tags text-secondary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item to="/price-lists" :class="getRoute() === 'price-lists' ? 'active' : ''"
          navText="Listas de precios">
          <template v-slot:icon>
            <i class="fa-solid fa-bars-staggered text-secondary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="mt-3 nav-item">
        <h6 class="text-xs ps-4 text-uppercase text-warning font-weight-bolder opacity-6 ms-2">
          Configuraciones
        </h6>
      </li>

      <li class="nav-item">
        <sidenav-item to="/profile" :class="getRoute() === 'profile' ? 'active' : ''" navText="Mi perfil">
          <template v-slot:icon>
            <i class="fa-solid fa-user text-secondary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-dropdown idName="usersDropdown" :options="dropdownUsersItems" menuName="usersDropdown"
          textDropDown="Usuarios">
          <template v-slot:icon>
            <i class="fa-solid fa-people-group text-secondary text-sm opacity-10"></i>
          </template>
        </sidenav-dropdown>
      </li>
      <!-- <li class="nav-item">
        <sidenav-item to="/users" :class="getRoute() === 'users' ? 'active' : ''" navText="Usuarios">
          <template v-slot:icon>
            <i class="fa-solid fa-people-group text-secondary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li> -->

      <li class="nav-item cursor-pointer" @click="showNewClient()">
        <div class="nav-link">
          <div class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
            <i class="fa-solid fa-display text-secondary text-sm opacity-10"></i>
          </div>
          <span class="nav-link-text text-secondary ms-1">Configurar Terminal</span>
        </div>
      </li>

      <li class="nav-item">
        <sidenav-item to="/my-business" class="text-info" :class="getRoute() === 'my-business' ? 'active' : ''"
          navText="Mi empresa">
          <template v-slot:icon>
            <i class="fa-solid fa-industry text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
    </ul>
  </div>

  <div class="pt-3 mx-3 mt-3 sidenav-footer">
    <sidenav-card />
  </div>
</template>
