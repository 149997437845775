<script setup>
// import { makeGetRequest, makePostRequest } from "@/utils/querysAPI.js";
import { onBeforeMount, onBeforeUnmount, onMounted, ref } from "vue";
// import { showToast, showModal } from "@/utils/modalShow.js";
// import { getDataSession } from "@/utils/manageSessions.js";
// import sisconImg from '@/assets/img/icon-rounded-100.png';
import { formatPhoneNumber } from "@/utils/formatters.js";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";
// import { getUserRoleText } from "@/utils/helper.js";
import { ElSelect } from "element-plus";
import { useStore } from "vuex";
import { bouncy } from 'ldrs';

bouncy.register();
const store = useStore();
const userInfo = ref({});
const rolesOptions = ref([
    {
        label: "Administrador",
        value: "ADMIN",
    },
    {
        label: "Cajero",
        value: "CASHIER",
    },
    {
        label: "Vendedor",
        value: "SELLER",
    },
]);
const roleSelected = ref(null);
// const roleText = ref('');
const showLoadingButton = ref(false);
const checkPassword = ref(true);
const checkedPasswords = ref(false);
// const dataSession = ref(getDataSession());
// const updatePassword = ref(false);
/* const loadProfileData = async () => {
    try {
        const businessId = dataSession.value.businessId;
        const userId = dataSession.value.userId;
        const request = await makeGetRequest(`/users/data/${businessId}/${userId}`);
        if (request.success) {
            roleText.value = getUserRoleText(request.data.role);
            userInfo.value = request.data.business_user;
        } else {
            throw new Error(request);
        }
    } catch (error) {
        console.log(error);
    }
}; */
const sendNewUser = async () => {
    console.log(userInfo.value)

};
const handleCheckPasswords = () => {
    // Chequear contraseñas
    if (userInfo.value.password !== userInfo.value.repeatPassword) {
        checkPassword.value = false;
        checkedPasswords.value = false;
        return false;
    } else {
        checkPassword.value = true;
        checkedPasswords.value = true;
        return true;
    }
};

onBeforeMount(() => {
    store.state.openedMenu = '';
});
onBeforeUnmount(() => {
    store.state.openedMenu = '';
});
onMounted(async () => {
    // await loadProfileData();
});
</script>
<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8">
                <div class="py-4 container-fluid">
                    <div class="row">
                        <div class="card">
                            <div class="card-header pb-0">
                                <div class="text-center">
                                    <p class="mb-0">Crear usuario</p>
                                </div>
                            </div>
                            <div class="card-body">
                                <form role="form" @submit.prevent="sendNewUser">
                                    <p class="text-uppercase text-sm">Información de usuario</p>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label class="form-control-label">Nombre</label>
                                            <argon-input type="text" v-model="userInfo.fname" />
                                        </div>
                                        <div class="col-md-6">
                                            <label for="example-text-input" class="form-control-label">Apellido</label>
                                            <argon-input type="text" v-model="userInfo.lname" />
                                        </div>
                                        <div class="col-md-6">
                                            <label for="example-text-input" class="form-control-label">Usuario</label>
                                            <argon-input type="text" autoComplete="off" v-model="userInfo.username" />
                                        </div>
                                        <div class="col-md-6">
                                            <label for="example-text-input" class="form-control-label">Correo
                                                electrónico</label>
                                            <argon-input type="email" autoComplete="off" v-model="userInfo.email" />
                                        </div>
                                        <div class="col-md-6">
                                            <label for="example-text-input"
                                                class="form-control-label">Contraseña</label>
                                            <argon-input id="password" type="password" :autoComplete="'off'"
                                                :success="checkedPasswords" v-model="userInfo.password"
                                                :error="!checkPassword" />
                                        </div>
                                        <div class="col-md-6">
                                            <label for="example-text-input" class="form-control-label">Repetir
                                                contraseña</label>
                                            <argon-input id="repeatPassword" type="password" :autoComplete="'off'"
                                                :success="checkedPasswords" v-model="userInfo.repeatPassword"
                                                :error="!checkPassword" @keyup="handleCheckPasswords" />
                                        </div>
                                    </div>
                                    <hr class="horizontal dark" />
                                    <p class="text-uppercase text-sm">Comportamiento de usuario</p>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label class="form-control-label">Rol de usuario</label>
                                            <el-select v-model="roleSelected" no-data-text="Sin datos"
                                                placeholder="Seleccione un rol" size="large" style="width: 100%">
                                                <el-option v-for="item in rolesOptions" :key="item.value"
                                                    :label="item.label" :value="item.value" />
                                            </el-select>
                                        </div>
                                    </div>
                                    <hr class="horizontal dark" />
                                    <p class="text-uppercase text-sm">Mas información</p>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label class="form-control-label">Dirección</label>
                                            <argon-input type="text" v-model="userInfo.address" />
                                        </div>
                                        <div class="col-md-4">
                                            <label class="form-control-label">Ciudad</label>
                                            <argon-input type="text" v-model="userInfo.city" />
                                        </div>
                                        <div class="col-md-4">
                                            <label class="form-control-label">Provincia</label>
                                            <argon-input type="text" v-model="userInfo.province" />
                                        </div>
                                        <div class="col-md-4">
                                            <label class="form-control-label">País</label>
                                            <argon-input type="text" v-model="userInfo.country" />
                                        </div>
                                        <div class="col-md-4">
                                            <label class="form-control-label">Código
                                                postal</label>
                                            <argon-input type="text" v-model="userInfo.zipCode" />
                                        </div>
                                        <div class="col-md-4">
                                            <label class="form-control-label">Teléfono</label>
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <span class="input-group-text">
                                                        <i class="fa-solid fa-phone"></i>
                                                    </span>
                                                    <input type="tel" class="form-control" @input="formatPhoneNumber"
                                                        v-model="userInfo.phone" pattern="\d*" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="horizontal dark" />
                                    <div class="row d-flex justify-content-center mt-4">
                                        <div class="w-30">
                                            <argon-button type="submit" id="saveUserData" color="info" size="md"
                                                class="d-flex flex-row gap-3 align-items-center justify-content-center w-100"
                                                :disabled="showLoadingButton">
                                                <l-bouncy v-if="showLoadingButton" size="30" speed="1" color="white"
                                                    stroke-length=".15" />
                                                <div v-else>
                                                    <i class="fa-regular text-lg fa-floppy-disk me-2"></i>
                                                    Crear usuario
                                                </div>
                                            </argon-button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
