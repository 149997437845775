/* eslint-disable */
const dbName = "sisconV2";
const dbVersion = 2;
const dbOSStructure = [
  {
    name: "products",
    indexes: ["barcode", "description", "name"],
  },
  {
    name: "invoices",
    indexes: [
      "total",
      "sendToAfip",
      "client_id",
      "billingType",
      "billingLetter",
      "status",
      "created_at",
      "documentNoShow",
    ],
  },
  {
    name: "clients",
    indexes: [
      "clientId",
      "fname",
      "lname",
      "docNo",
      "email",
      "address",
      "phone",
    ],
  },
  {
    name: "uConfig",
    indexes: null,
  },
];
let db;

// TODO: Continuar con las collection names para exportar
export const collectionNames = {
  products: "products",
  clients: "clients",
  bills: "invoices",
};

const openDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, dbVersion);

    request.onupgradeneeded = (event) => {
      db = event.target.result;
      try {
        for (const collection of dbOSStructure) {
          if (!db.objectStoreNames.contains(collection.name)) {
            const objectStore = db.createObjectStore(collection.name, {
              keyPath: "id",
              autoIncrement: true,
            });

            if (collection.indexes) {
              collection.indexes.forEach((index) => {
                objectStore.createIndex(index, index, { unique: false });
              });
            }
          }
        }
        resolve(db);
      } catch (error) {
        reject(`Error creating IDBStructure. Please reload the page.`);
      }
    };

    request.onsuccess = (event) => {
      db = event.target.result;
      resolve(db);
    };

    request.onerror = (event) => {
      reject(`Error opening database: ${event.target.errorCode}`);
    };

    // Manejar cambio de versión por otra instancia
    request.onversionchange = function () {
      db.close();
      reject("Existen cambios pendientes. Por favor, recargue la página.");
    };
  });
};

const getObjectStore = (storeName, mode) => {
  if (!db.objectStoreNames.contains(storeName)) {
    return false;
  }
  const transaction = db.transaction(storeName, mode);
  return transaction.objectStore(storeName);
};

/**
 *
 * @param {*} colectName
 * @param {*} arrayBatch
 * @returns
 */
export const saveBatch = async (colectName, arrayBatch) => {
  await openDB();

  const objectStore = getObjectStore(colectName, "readwrite");
  const deleteAllAndAdd = async (item) => {
    return new Promise((resolve, reject) => {
      if (!objectStore) {
        // Devuelve error si no encuentra el nombre de la colección
        reject(`Error getting colection.`);
      }
      // Limpiamos los registros dentro de la colección
      const clearRequest = objectStore.clear();
      clearRequest.onerror = (event) =>
        reject(`Clear error: ${event.target.errorCode}`);

      /* const checkAndUpdate = async (item) => {
        return new Promise((resolve, reject) => {
          const getRequest = objectStore.get(item.id);

          getRequest.onsuccess = (event) => {
            const existingItem = event.target.result;
            if (existingItem) {
              // El registro existe, actualizar si es necesario
              const updatedItem = { ...existingItem, ...item };
              const updateRequest = objectStore.put(updatedItem);
              updateRequest.onsuccess = () => resolve();
              updateRequest.onerror = (event) =>
                reject(`Update error: ${event.target.errorCode}`);
            } else {
              // El registro no existe, agregar nuevo
              const addRequest = objectStore.add(item);
              addRequest.onsuccess = () => resolve();
              addRequest.onerror = (event) =>
                reject(`Add error: ${event.target.errorCode}`);
            }
          };

          getRequest.onerror = (event) => {
            reject(`Get error: ${event.target.errorCode}`);
          };
        });
      }; */

      clearRequest.onsuccess = () => {
        // Agrega el nuevo registro
        const addRequest = objectStore.add(item);
        addRequest.onsuccess = () => resolve();
        addRequest.onerror = (event) =>
          reject(`Add error: ${event.target.errorCode}`);
      };
    });
  };

  /* const deleteIfNotExistsAndAdd = async (item) => {
    return new Promise((resolve, reject) => {
      const getRequest = objectStore.get(item.id);
  
      getRequest.onsuccess = (event) => {
        const existingItem = event.target.result;
        if (existingItem) {
          // El registro existe, actualizar si es necesario
          const updatedItem = { ...existingItem, ...item };
          const updateRequest = objectStore.put(updatedItem);
          updateRequest.onsuccess = () => resolve();
          updateRequest.onerror = (event) =>
            reject(`Update error: ${event.target.errorCode}`);
        } else {
          // El registro no existe, eliminar todos los registros y agregar nuevo
          const clearRequest = objectStore.clear();
          clearRequest.onsuccess = () => {
            const addRequest = objectStore.add(item);
            addRequest.onsuccess = () => resolve();
            addRequest.onerror = (event) =>
              reject(`Add error: ${event.target.errorCode}`);
          };
          clearRequest.onerror = (event) =>
            reject(`Clear error: ${event.target.errorCode}`);
        }
      };
  
      getRequest.onerror = (event) => {
        reject(`Get error: ${event.target.errorCode}`);
      };
    });
  }; */

  try {
    // Esta función recorre cada elemnto del objeto.
    await Promise.all(arrayBatch.map((item) => deleteAllAndAdd(item)));
    const countRequest = objectStore.count();

    return new Promise((resolve, reject) => {
      countRequest.onsuccess = () => {
        db.close();
        resolve({
          success: true,
          count: countRequest.result,
        });
      };

      countRequest.onerror = () => {
        db.close();
        reject({
          success: false,
          error: `Error counting documents: ${countRequest.error}`,
        });
      };
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const searchItemsFromParam = async (colectName, param) => {
  await openDB();

  return new Promise((resolve, reject) => {
    const transaction = db.transaction([colectName], "readonly");
    const objectStore = transaction.objectStore(colectName);

    let results = [];
    const searchIndexes = objectStore.indexNames; // Obtiene los nombres de todos los índices

    const searchInIndex = (indexName) => {
      return new Promise((resolveIndex, rejectIndex) => {
        const index = objectStore.index(indexName);
        const request = index.openCursor();

        request.onsuccess = (event) => {
          const cursor = event.target.result;
          if (cursor) {
            const value = cursor.value[indexName].toLowerCase();
            if (value.includes(param)) {
              results.push(cursor.value);
            }
            cursor.continue();
          } else {
            resolveIndex();
          }
        };

        request.onerror = (event) => {
          rejectIndex(
            `Search error in index ${indexName}: ${event.target.errorCode}`
          );
        };
      });
    };

    Promise.all(
      Array.from(searchIndexes).map((indexName) => searchInIndex(indexName))
    )
      .then(() => {
        results = results.filter(
          (value, index, self) =>
            self.findIndex((v) => v.id === value.id) === index
        );
        resolve(results);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getItemsFromCollection = async (
  collectionName,
  orderBy = null
) => {
  await openDB();

  return new Promise((resolve, reject) => {
    const transaction = db.transaction([collectionName], "readonly");
    const objectStore = transaction.objectStore(collectionName);
    const results = [];

    const request = objectStore.openCursor();

    request.onsuccess = (event) => {
      const cursor = event.target.result;
      if (cursor) {
        results.push(cursor.value);
        cursor.continue();
      } else {
        if (orderBy !== "") {
          results.sort((a, b) => {
            const nameA = String(a[orderBy]).toLowerCase();
            const nameB = String(b[orderBy]).toLowerCase();
            return nameA.localeCompare(nameB);
          });
        }
        resolve(results);
      }
    };

    request.onerror = (event) => {
      reject(`Error searching in collection: ${event.target.errorCode}`);
    };
  });
};

export const getItemFromId = async (colectName, id) => {
  await openDB();

  return new Promise((resolve, reject) => {
    const transaction = db.transaction([colectName], "readonly");
    const objectStore = transaction.objectStore(colectName);

    const request = objectStore.get(id); // Busca el documento por ID

    request.onsuccess = (event) => {
      const result = event.target.result;
      if (result) {
        resolve(result); // Devuelve el documento encontrado
      } else {
        reject(`No se encontró ningún documento con el ID ${id}`);
      }
    };

    request.onerror = (event) => {
      reject(
        `Error al buscar el documento con el ID ${id}: ${event.target.errorCode}`
      );
    };
  });
};

export const updateItemFromId = async (collectionName, id, updateData) => {
  await openDB();

  return new Promise((resolve, reject) => {
    const transaction = db.transaction([collectionName], "readwrite");
    const objectStore = transaction.objectStore(collectionName);

    const request = objectStore.get(id); // Busca el documento por ID

    request.onsuccess = (event) => {
      const existingItem = event.target.result;
      if (existingItem) {
        // Actualiza los datos del objeto
        Object.assign(existingItem, updateData);
        // Guarda los cambios
        const putRequest = objectStore.put(existingItem);
        putRequest.onsuccess = () => {
          resolve(existingItem); // Devuelve el objeto actualizado
        };
        putRequest.onerror = (event) => {
          reject(`Error al guardar los cambios: ${event.target.errorCode}`);
        };
      } else {
        reject(`No se encontró ningún documento con el ID ${id}`);
      }
    };

    request.onerror = (event) => {
      reject(
        `Error al buscar el documento con el ID ${id}: ${event.target.errorCode}`
      );
    };
  });
};

export const checkCollectionEmpty = async (colectName) => {
  await openDB();

  return new Promise((resolve, reject) => {
    const transaction = db.transaction([colectName], "readonly");
    const objectStore = transaction.objectStore(colectName);

    const request = objectStore.openCursor();

    request.onsuccess = (event) => {
      const cursor = event.target.result;
      if (cursor) {
        // Si se encuentra al menos un valor, la colección no está vacía
        resolve(false);
      } else {
        // No se encontraron valores, la colección está vacía
        resolve(true);
      }
    };

    request.onerror = (event) => {
      reject(
        `Error al buscar en la colección ${colectName}: ${event.target.errorCode}`
      );
    };
  });
};
