<script setup>
// import { saveBatch, searchItemsFromParam, checkCollectionEmpty, getItemsFromCollection } from '@/plugins/dataServer.js';
import { saveBatch, searchItemsFromParam, getItemsFromCollection } from '@/plugins/dataServer.js';
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import { onBeforeMount, ref, computed, onMounted } from "vue";
import { getDataSession } from '@/utils/manageSessions.js';
import ArgonButton from "@/components/ArgonButton.vue";
import { makeGetRequest } from "@/utils/querysAPI.js";
import { formatNumber } from '@/utils/formatters.js';
import NoDocument from './components/NoDocument.vue';
import ArgonBadge from "@components/ArgonBadge.vue";
import setTooltip from "@/assets/js/tooltip.js";
import { showModal, showToast } from "@/utils/modalShow.js";
import Loader from './components/Loader.vue';
import Paginate from "vuejs-paginate-next";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

const searchItemParam = ref('');
const collectionName = "products";
const dataSession = getDataSession();
const router = useRouter();
const route = useRoute();
const store = useStore();
const products = ref([]);
const currentPage = computed(() => Number(route.params.page));
const itemsPerPage = computed(() => store.state.itemsPerPage);
const totalPages = computed(() => Math.ceil(products.value.length / itemsPerPage.value));
const noDocClass = computed(() => products.value.length > 0 ? 'col-lg-9' : 'col-lg-12');
const loadingContent = ref(true);
const productsQty = ref(0);
const ammountTotalProducts = ref(0);
const filteredProducts = ref([]);
const searchTimeout = ref(null);
const searchActive = ref(false);
const searchInactive = ref(false);

const paginatedItems = computed(() => {
    const startIndex = (currentPage.value - 1) * itemsPerPage.value;
    const endIndex = startIndex + itemsPerPage.value;
    return products.value.slice(startIndex, endIndex);
});

const setPage = (page) => {
    if (currentPage.value !== page) {
        router.push({ path: `/products/view/${page}` });
    }
};

/* const getSequentialNumber = (index) => {
    return (currentPage.value - 1) * itemsPerPage.value + index + 1;
}; */

const preCheckLoading = async () => {
    // TODO: IMPORTANT! Arreglar esta lógica para tomar los productos nuevos
    // const isCollectionEmpty = await checkCollectionEmpty(collectionName);
    // if (isCollectionEmpty) {
        await loadDataFromServer();
    // } else if (!isCollectionEmpty) {
    //     const itemsIdb = await getItemsFromCollection(collectionName, 'name');
    //     products.value = itemsIdb;
    //     productsQty.value = 0;
    //     ammountTotalProducts.value = 0;
    // }
    loadingContent.value = false;
};

const loadDataFromServer = async () => {
    try {
        const businessId = dataSession.businessId;
        const userId = dataSession.userId;
        const urlProductsList = 'products/list-view/' + businessId + "/" + userId + "/false";
        const request = await makeGetRequest(urlProductsList);
        if (request.success) {
            const productsSavedIdb = await saveBatch(collectionName, request.data);
            if (!productsSavedIdb.success) {
                showToast.fire({
                    icon: "error",
                    title: "Ha ocurrido un error al cargar los datos de los productos. Recargue la página."
                });
                return;
            }
            const productsOnIdb = await getItemsFromCollection(collectionName)
            products.value = productsOnIdb;
            productsQty.value = formatNumber(request.headers.totalQty, 0, 0);
            ammountTotalProducts.value = formatNumber(request.headers.totalAmmount);
        }
    } catch (error) {
        console.log(error);
    }
};

/* const showActives = (active) => {
    if (active) searchActive.value = !searchActive.value;
    if (!active) searchInactive.value = !searchInactive.value;

    const arrayToFilter = filteredProducts.value.length > 0 ? filteredProducts.value : paginatedItems.value;
    const productsFound = arrayToFilter.filter(item => {
        return !!item.status === active;
    });
    if (productsFound.length > 0) {
        productsFound.forEach(async (product) => {
            const isProductInSearch = filteredProducts.value.find(found => found.productId === product.productId);
            if (!isProductInSearch) {
                filteredProducts.value.push(product);
            }
        });
    }
}; */

const cleanFilters = () => {
    searchItemParam.value = "";
    filteredProducts.value = [];
    searchActive.value = false;
    searchInactive.value = false;
};

/* const showStatusProduct = (obj) => {
    const firstProductInfo = obj.products_business[0];
    return firstProductInfo.status;
}; */

const findProductInfo = (id) => {
    return products.value.find(product => product.id === id);
};
const showInfoModalProduct = (id) => {
    const product = findProductInfo(id);
    const countStock = product.countStock == 0 ? 'No' : 'Si';
    const withTaxes = product.withTaxes == 0 ? 'No' : 'Si';
    const barcode = product.barcode !== null ? product.barcode : '-';
    const innerCode = product.innerCode !== null ? product.innerCode : '-';
    const description = !product.description ? "S/D" : product.description;
    const sellPrice = formatNumber(product.sellPrice);
    const costPrice = formatNumber(product.costPrice);
    return `
        <div class="w-100 mt-2 overflow-hidden">
            <div class="row mb-1"><h6 class="text-start mb-0">Información del producto</h6></div>
            <div class="row mb-5">
                <div class="col-lg-5">
                    <label class="form-control-label">Nombre del producto</label>
                    <input type="text" class="form-control" readonly value="${product.name}" />
                </div>
                <div class="col-lg-7">
                    <label class="form-control-label">Descripción</label>
                    <input type="text" class="form-control" readonly value="${description}" />
                </div>
            </div>
            <div class="row mb-1"><h6 class="text-start mb-0">Información secundaria</h6></div>
            <div class="row mb-5">
                <div class="col-lg-4">
                    <label class="form-control-label">Código de barra</label>
                    <input type="text" class="form-control" readonly value="${barcode}" />
                </div>
                <div class="col-lg-3">
                    <label class="form-control-label">Código interno</label>
                    <input type="text" class="form-control" readonly value="${innerCode}" />
                </div>
                <div class="col-lg-2">
                    <label class="form-control-label">Cuenta stock</label>
                    <input type="text" class="form-control text-center" readonly value="${countStock}" />
                </div>
                <div class="col-lg-2">
                    <label class="form-control-label">Cuenta impuesto</label>
                    <input type="text" class="form-control text-center" readonly value="${withTaxes}" />
                </div>
            </div>
            <div class="row mb-1"><h6 class="text-start mb-0">Valores</h6></div>
            <div class="row mb-4">
                <div class="col-lg-3">
                    <label class="form-control-label">Precio de costo</label>
                    <input type="text" class="form-control text-end" readonly value="$ ${costPrice}" />
                </div>
                <div class="col-lg-3">
                    <label class="form-control-label">Precio de venta</label>
                    <input type="text" class="form-control text-end" readonly value="$ ${sellPrice}" />
                </div>
                <div class="col-lg-3">
                    <label class="form-control-label">Porcentaje de ganancia</label>
                    <input type="text" class="form-control text-end" readonly value="${product.profitPercent} %" />
                </div>
            </div>
        </div>
    `;
    /* return `
        <table class="table table-hover table-borderless align-items-center">
            <tbody>
                <tr>
                    <td class="fst-italic">#</td>
                    <td class="font-weight-bold text-info">${product.productId}</td>
                </tr>
                <tr>
                    <td class="fst-italic">Descripción</td>
                    <td class="text-wrap">${product.description}</td>
                </tr>
                <tr>
                    <td class="fst-italic">Código de barra</td>
                    <td>${barcode}</td>
                </tr>
                <tr>
                    <td class="fst-italic">Código interno</td>
                    <td>${innerCode}</td>
                </tr>
                <tr>
                    <td class="fst-italic">Precio de costo</td>
                    <td class="font-weight-bold">$${costPrice}</td>
                </tr>
                <tr>
                    <td class="fst-italic">Precio de venta</td>
                    <td class="font-weight-bold">$${sellPrice}</td>
                </tr>
                <tr>
                    <td class="fst-italic">Porcentaje de ganancia</td>
                    <td class="font-weight-bold">${product.profitPercent}%</td>
                </tr>
                <tr>
                    <td class="fst-italic">Cuenta stock</td>
                    <td>${countStock}</td>
                </tr>
                <tr>
                    <td class="fst-italic">Cuenta impuesto</td>
                    <td>${withTaxes}</td>
                </tr>
            </tbody>
        </table
    `; */
};
const openProdModal = (id) => {
    const productInfo = findProductInfo(id);
    showModal({
        title: productInfo.name,
        text: showInfoModalProduct(id),
        isHtml: true,
        icon: "info",
        showCancel: false,
        outClick: true,
        width: '70%',
        confirmText: "Editar",
    }).then((result) => {
        if (result.isConfirmed) {
            router.push(`/products/edit/${id}`);
        }
    });
};
const redirectEditProducto = (id) => {
    console.log(id);
    router.push(`/products/edit/${id}`);
};

const searchProduct = async () => {
    if (searchItemParam.value === '') {
        filteredProducts.value = [];
        return;
    }

    clearTimeout(searchTimeout.value);

    searchTimeout.value = setTimeout(async () => {
        try {
            const normalizedSearchTerm = searchItemParam.value.toLowerCase().trim();
            const dataFromInnerDB = await searchItemsFromParam(collectionName, normalizedSearchTerm);

            if (dataFromInnerDB.length > 0) {
                filteredProducts.value = dataFromInnerDB;
            } else {
                filteredProducts.value = [];
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, 500);
};
const cleanText = () => {
    searchItemParam.value = '';
    searchProduct();
};

onMounted(async () => {
    await preCheckLoading();
    setTooltip();
});
onBeforeMount(() => {
    store.state.openedMenu = 'products';
});
</script>
<template>
    <div v-if="loadingContent">
        <Loader />
    </div>
    <div v-else class="py-4 container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div v-if="products.length > 0" class="row">
                    <div class="col-lg-4 col-md-6 col-12">
                        <mini-statistics-card title="Cantidad de productos" :value="productsQty" description="<span
                            class='text-sm font-weight-bolder text-success'
                            >+55%</span> que ayer" :icon="{
                                component: 'ni ni-money-coins',
                                background: 'bg-gradient-primary',
                                shape: 'rounded-circle',
                            }" />
                    </div>
                    <div class="col-lg-4 col-md-6 col-12">
                        <mini-statistics-card title="Monto contabilizado actual" :value="'$ ' + ammountTotalProducts"
                            description="<span
                            class='text-sm font-weight-bolder text-success'
                            >+3%</span> que ayer" :icon="{
                                component: 'ni ni-world',
                                background: 'bg-gradient-danger',
                                shape: 'rounded-circle',
                            }" />
                    </div>
                    <div class="col-lg-4 col-md-6 col-12">
                        <mini-statistics-card title="Monto contabilizado actual" :value="'$ ' + ammountTotalProducts"
                            description="<span
                            class='text-sm font-weight-bolder text-success'
                            >+3%</span> que ayer" :icon="{
                                component: 'ni ni-world',
                                background: 'bg-gradient-danger',
                                shape: 'rounded-circle',
                            }" />
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="mb-lg-0 mb-4" :class="noDocClass">
                        <div class="card">
                            <div class="p-3 pb-0 card-header d-flex justify-content-between align-items-center w-100">
                                <div v-if="products.length > 0" class="d-flex justify-content-between">
                                    <h6 class="mb-2">Todos los productos</h6>
                                </div>
                                <!-- <div v-if="products.length > 0"
                                    class="pe-md-3 d-flex col-md-1 align-items-center justify-content-center">
                                    <router-link to="/products/new">
                                        <argon-button refBtn="newProduct" color="info" size="lg"
                                            data-bs-toggle="tooltip" data-bs-placement="bottom"
                                            data-bs-title="Crear nuevo producto">
                                            <i class="fa-solid fa-plus"></i>
                                            Nuevo producto
                                        </argon-button>
                                    </router-link>
                                </div> -->
                            </div>
                            <div v-if="products.length == 0" class="w-100">
                                <no-document iconSVG="NoDocumentSVG" text="No se encontraron productos ingresados"
                                    redirectTo="/products/new" redirectText="Ingresar un nuevo producto" />
                            </div>
                            <div v-else class="table-responsive position-relative px-4 py-2">
                                <table class="table table-hover align-items-center">
                                    <thead>
                                        <tr>
                                            <!-- <th
                                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                #
                                            </th> -->
                                            <th
                                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                Cód. barra / EAN
                                            </th>
                                            <th
                                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center">
                                                Nombre
                                            </th>
                                            <th
                                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                Precio venta
                                            </th>
                                            <th
                                                class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2">
                                                Estado
                                            </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="filteredProducts.length > 0">
                                        <tr v-for="(product, index) in filteredProducts" :key="index">
                                            <!-- <td>
                                                <div>
                                                    <h6
                                                        class="mb-0 font-weight-bolder text-sm text-center image-rounded text-info">
                                                        {{ getSequentialNumber(index) }}
                                                    </h6>
                                                </div>
                                            </td> -->
                                            <td>
                                                <div class="px-2 py-1 d-flex align-items-center">
                                                    <div class="">
                                                        <i class="fa-solid fa-barcode" />
                                                    </div>
                                                    <div class="ms-4">
                                                        <h6 class="mb-0 text-sm">{{ product.barcode
                                                            !== null ?
                                                            product.barcode : '-' }}</h6>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="w-30 text-wrap">
                                                <h6 class="mb-0 text-sm">{{ product.name }}</h6>
                                            </td>
                                            <td>
                                                <div class="text-center">
                                                    <h6 class="mb-0 text-sm">$ {{
                                                        formatNumber(product.sellPrice)
                                                        }}
                                                    </h6>
                                                </div>
                                            </td>
                                            <td class="text-sm align-middle">
                                                <div class="text-center col">
                                                    <argon-badge v-if="product.status" variant="gradient"
                                                        color="success">
                                                        Activo
                                                    </argon-badge>
                                                    <argon-badge v-else variant="gradient" color="danger">
                                                        Inactivo
                                                    </argon-badge>
                                                </div>
                                            </td>
                                            <td class="align-middle">
                                                <button class="btn btn-link text-info w-10 mb-0"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    data-bs-title="Información del producto"
                                                    @click="openProdModal(product.id)">
                                                    <i class="fa-solid fa-circle-info text-lg" aria-hidden="true"></i>
                                                </button>
                                                <button class="btn btn-link text-info w-10  mb-0"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    data-bs-title="Editar producto"
                                                    @click="redirectEditProducto(product.id)">
                                                    <i class="fa-solid fa-pen-to-square text-lg" aria-hidden="true"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr v-for="(product, index) in paginatedItems" :key="index">
                                            <!-- <td>
                                                <div>
                                                    <h6
                                                        class="mb-0 font-weight-bolder text-sm text-center image-rounded text-info">
                                                        {{ getSequentialNumber(index) }}
                                                    </h6>
                                                </div>
                                            </td> -->
                                            <td>
                                                <div class="px-2 py-1 d-flex align-items-center">
                                                    <div class="">
                                                        <i class="fa-solid fa-barcode" />
                                                    </div>
                                                    <div class="ms-4">
                                                        <h6 class="mb-0 text-sm">{{ product.barcode
                                                            !== null ?
                                                            product.barcode : '-' }}</h6>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="w-30 text-wrap">
                                                <h6 class="mb-0 text-sm">{{ product.name }}</h6>
                                            </td>
                                            <td>
                                                <div class="text-center">
                                                    <h6 class="mb-0 text-sm">$ {{
                                                        formatNumber(product.sellPrice)
                                                        }}
                                                    </h6>
                                                </div>
                                            </td>
                                            <td class="text-sm align-middle">
                                                <div class="text-center col">
                                                    <argon-badge v-if="product.status" variant="gradient"
                                                        color="success">
                                                        Activo
                                                    </argon-badge>
                                                    <argon-badge v-else variant="gradient" color="danger">
                                                        Inactivo
                                                    </argon-badge>
                                                </div>
                                            </td>
                                            <td class="align-middle">
                                                <button class="btn btn-link text-info w-10 mb-0"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    data-bs-title="Información del producto"
                                                    @click="openProdModal(product.id)">
                                                    <i class="fa-solid fa-circle-info text-lg" aria-hidden="true"></i>
                                                </button>
                                                <button class="btn btn-link text-info w-10  mb-0"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    data-bs-title="Editar producto"
                                                    @click="redirectEditProducto(product.id)">
                                                    <i class="fa-solid fa-pen-to-square text-lg" aria-hidden="true"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="row">
                                    <div class="col-md-4"></div>
                                    <div v-if="products.length > itemsPerPage && filteredProducts.length == 0"
                                        class="col-md-4 d-flex justify-content-center">
                                        <paginate v-model="currentPage" :page-count="totalPages"
                                            :click-handler="setPage" :page-range="2" :prev-text="'<'" :next-text="'>'"
                                            :container-class="'pagination pagination-info pagination-lg cursor-pointer'" />
                                    </div>
                                    <div v-else class="col-md-4" />
                                    <div
                                        class="text-center d-flex flex-column align-items-center justify-content-center col-md-4">
                                        <p class="text-secondary text-xs opacity-9">
                                            Se encontraron <strong>{{ products.length }}</strong> productos.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="products.length > 0" class="col-lg-3">
                        <div class="position-sticky top-8 z-index-sticky">
                            <div class="card shadow-lg pb-3">
                                <div class="card-header pb-0 px-3">
                                    <h6 class="mb-0">Opciones de búsqueda</h6>
                                </div>
                                <div class="card-body">
                                    <div class="px-2">
                                        <!-- <div class="text-sm">Búsqueda general:</div> -->
                                        <div class="input-group">
                                            <input type="text" id="searchParamText" class="form-control"
                                                placeholder="Nombre o descripción de producto..."
                                                v-model="searchItemParam" @keyup="searchProduct" />
                                            <span v-if="searchItemParam !== ''" class="input-group-text cursor-pointer"
                                                @click="cleanText">
                                                <i class="fa-solid fa-x text-secondary"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="px-2 pt-4">
                                        <div class="text-sm">Por estado:</div>
                                        <div
                                            class="d-flex gap-3 flex-row flex-wrap align-items-center justify-content-center my-1">
                                            <div class="btn-group">
                                                <a href="javascript:;" class="btn btn-outline-success"
                                                    :class="searchActive ? 'active' : ''" aria-current="page">Activo</a>
                                                <!-- TODO: filter by actives -->
                                                <!-- <a href="javascript:;" class="btn btn-outline-success"
                                                :class="searchActive ? 'active' : ''" aria-current="page"
                                                @click="showActives(true)">Activo</a> -->
                                                <a href="javascript:;" class="btn btn-outline-danger"
                                                    :class="searchInactive ? 'active' : ''">Inactivo</a>
                                                <!-- TODO: filter by inactives -->
                                                <!-- <a href="javascript:;" class="btn btn-outline-danger"
                                                    :class="searchInactive ? 'active' : ''"
                                                    @click="showActives(false)">Inactivo</a> -->
                                            </div>
                                            <!-- <argon-button refBtn="btnPresup" color="success" size="md" variant="outline"
                                                :activating="true" class="d-flex flex-row gap-3 align-items-center">
                                                Activo
                                            </argon-button>
                                            <argon-button refBtn="btnBills" color="danger" size="md" variant="outline"
                                                :activating="true" class="d-flex flex-row gap-3 align-items-center">
                                                Inactivo
                                            </argon-button> -->
                                        </div>
                                    </div>
                                    <div class="px-2 pt-2">
                                        <div class="text-sm">Precio de venta:</div>
                                        <div class="form-group mb-1">
                                            <label for="form-control-label">Desde</label>
                                            <div class="input-group w-80 mx-auto">
                                                <span class="input-group-text text-body">
                                                    <i class="fa-solid fa-dollar-sign" aria-hidden="true"></i>
                                                </span>
                                                <input type="number" class="form-control text-end" />
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="form-control-label">Hasta</label>
                                            <div class="input-group w-80 mx-auto">
                                                <span class="input-group-text text-body">
                                                    <i class="fa-solid fa-dollar-sign" aria-hidden="true"></i>
                                                </span>
                                                <input type="number" class="form-control text-end" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="px-2 pt-2">
                                        <div class="text-sm">Otros filtros:</div>
                                        <div class="d-flex gap-3 flex-row flex-wrap my-1 ms-3">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value=""
                                                    id="flexCheckDefault">
                                                <label class="form-check-label" for="flexCheckDefault">
                                                    Cuenta stock
                                                </label>
                                            </div>
                                            <!-- <argon-button refBtn="btnPresup" color="dark" size="md" variant="outline"
                                                :activating="true" class="d-flex flex-row gap-3 align-items-center">
                                                Cuenta stock
                                            </argon-button> -->
                                        </div>
                                    </div>
                                    <hr class="horizontal dark">
                                    <div class="px-3">
                                        <div class="mb-3 d-flex justify-content-center">
                                            <argon-button refBtn="newProduct" color="danger" size="sm"
                                                @click="cleanFilters">
                                                <i class="fa-solid fa-broom"></i>
                                                Limpiar filtros
                                            </argon-button>
                                        </div>
                                        <router-link to="/products/new">
                                            <argon-button :fullWidth="true" refBtn="newProduct" color="info" size="md">
                                                <i class="fa-solid fa-plus"></i>
                                                Nuevo producto
                                            </argon-button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.image-container {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>
