<script setup>
import ArgonBadge from "@components/ArgonBadge.vue";
import { RouterLink } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
// import { clickOutSide as vClickOutSide } from '@mahdikhashan/vue3-click-outside'

const store = useStore();
const classArrowDD = 'rotate-chevron-90';
const classHideDrop = 'hide-dropdown';
const classShowDrop = 'show-dropdown';
const showNewClient = () => {
    return Swal.fire({
        title: "Good job!",
        text: "You clicked the button!",
        icon: "success"
    });
}

const toggleDropdown = (id) => {
    store.state.openedMenu = id;
    /* const currentPageOnStore = store.state.openedMenu;
    const fatherDropdown = document.getElementById(id);
    const elementDropdown = fatherDropdown.querySelector('.dropdown');
    const arrowDD = fatherDropdown.querySelector('.arrow-dropdown');
    const isDropdowned = elementDropdown.classList.contains(classHideDrop);

    if (isDropdowned && currentPageOnStore == id) {
        elementDropdown.classList.remove(classHideDrop);
        elementDropdown.classList.add(classShowDrop);
        arrowDD.classList.add(classArrowDD);
    } else {
        elementDropdown.classList.remove(classShowDrop);
        elementDropdown.classList.add(classHideDrop);
        arrowDD.classList.remove(classArrowDD);
    } */
};

/* const hideThisDropdown = () => {
    document.querySelector('ul.dropdown').classList.remove(classShowDrop);
    document.querySelector('ul.dropdown').classList.add(classHideDrop);
}; */
const handleSubitemAnimation = (event) => {
    event.target.firstChild.classList.add('subitemIn');
};
const handleSubitemAnimationOut = (event) => {
    event.target.firstChild.classList.remove('subitemIn');
};

defineProps({
    menuName: {
        type: String,
        required: true,
    },
    textDropDown: {
        type: String,
        required: true,
    },
    options: {
        type: Array,
        required: true,
    },
    idName: {
        type: String,
        required: true,
    },
    badgeText: {
        type: String,
        default: "",
    }
});
</script>
<template>
    <div @click="toggleDropdown(idName)" :id="idName" class="cursor-pointer dropDownFather">
        <div class="nav-link justify-content-between">
            <div class="d-flex flex-row align-items-center">
                <div class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                    <slot name="icon"></slot>
                </div>
                <div class="nav-link-text ms-1">{{
                    textDropDown
                    }}</div>
            </div>
            <argon-badge variant="gradient" color="success">
                {{ badgeText }}
            </argon-badge>
            <div class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center arrow-dropdown"
                :class="store.state.openedMenu == idName ? classArrowDD : ''">
                <i class="fa-solid fa-chevron-left text-secondary"></i>
            </div>
        </div>
        <ul :class="store.state.openedMenu == idName ? classShowDrop : classHideDrop" class="navbar-nav dropdown">
            <div>
                <li v-for="(option, index) in options" :key="index" class="nav-item">
                    <a v-if="option.to == ''" @click="showNewClient" @mouseenter="handleSubitemAnimation"
                        @mouseleave="handleSubitemAnimationOut" href="javascript:;" class="nav-link subItemDropdown">
                        <div
                            class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center subitemAnimate">
                            <i class="fa-solid fa-circle-chevron-right text-info"></i>
                        </div>
                        <span class="item-title">{{ option.title }}</span>
                    </a>
                    <router-link v-else :to="option.to" class="nav-link subItemDropdown"
                        @mouseenter="handleSubitemAnimation" @mouseleave="handleSubitemAnimationOut">
                        <div
                            class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center subitemAnimate">
                            <i class="fa-solid fa-circle-chevron-right text-info"></i>
                        </div>
                        <span class="item-title">{{ option.title }}</span>
                    </router-link>
                </li>
                <hr class="horizontal dark" />
            </div>
        </ul>
    </div>
</template>
<style scoped>
.dropDownFather {
    overflow: hidden;
}

.arrow-dropdown {
    transition: transform .1s ease-out;
}

.rotate-chevron-90 {
    transform: rotate(-90deg);
}

.dropdown>div {
    transition: transform .3s ease-out, opacity .5s ease-out, max-height .5s ease-out;
}

.hide-dropdown>div {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transform: translateY(-10%);
    transform-origin: top;
}

.show-dropdown>div {
    opacity: 1;
    max-height: auto;
}

.subitemAnimate {
    transition: all .1s ease-out;
}

.subitemIn {
    margin-right: 10px;
}
</style>
