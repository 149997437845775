import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "@/router";
import "@assets/css/nucleo-icons.css";
import "@assets/css/nucleo-svg.css";
import ArgonDashboard from "./argon-dashboard";
import ElementPlus from "element-plus";
import esAr from "element-plus/es/locale/lang/es";
import "element-plus/dist/index.css";

import "sweetalert2/dist/sweetalert2.min.css";

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.use(ElementPlus, {
  locale: esAr,
});
appInstance.mount("#app");
