<script setup>
import { logoutUser, getDataSession } from '@/utils/manageSessions.js';
import { useRoute, useRouter, RouterLink } from 'vue-router';
import setTooltip from "@/assets/js/tooltip.js";
import { computed, onMounted, ref } from "vue";
import Breadcrumbs from "../Breadcrumbs.vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";

const showNewClient = () => {
  return Swal.fire({
    title: "Good job!",
    text: "You clicked the button!",
    icon: "success"
  });
}

const showMenu = ref(false);
const store = useStore();
const route = useRoute();
const router = useRouter();
const isConnection = computed(() => store.state.connectedToInternet);
const showMessages = computed(() => store.state.showMessages);
const dataUser = ref(getDataSession());

const currentRouteName = computed(() => {
  return route.name;
});
const currentDirectory = computed(() => {
  let dir = route.path.split("/")[1];
  return dir.charAt(0).toUpperCase() + dir.slice(1);
});

const minimizeSidebar = () => store.commit("sidebarMinimize");
const toggleConfigurator = () => store.commit("toggleConfigurator");

const logOut = () => {
  logoutUser();
  router.push({ path: '/signin' });
};

const closeMenu = () => {
  setTimeout(() => {
    showMenu.value = false;
  }, 100);
};

onMounted(() => {
  setTooltip();
})
</script>
<template>
  <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl"
    :class="isRTL ? 'top-0 position-sticky z-index-sticky' : ''" v-bind="$attrs" id="navbarBlur" data-scroll="true">
    <div class="px-3 pt-3 container-fluid">
      <div class="navbar-collapse">
        <breadcrumbs :current-page="currentRouteName" :current-directory="currentDirectory" />
      </div>

      <!-- Siscon Img -->
      <!-- <div class="d-flex align-items-center justify-content-center">
        <div class="sidenav-header" id="logoHeaderSiscon">
          <router-link to="/" class="w-100 h-100">
            <img src="@/assets/img/icon-rounded-100.png" alt="Siscon System" class="h-100 w-100">
          </router-link>
        </div>
      </div> -->

      <div class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4" id="navbar">
        <ul class="navbar-nav justify-content-end">
          <li v-if="isConnection" class="nav-item d-flex align-items-center me-3 cursor-pointer"
            data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Estás conectado a internet">
            <span class="badge bg-gradient-success badge-md">
              <i class="fa-solid fa-wifi text-lg opacity-10"></i>
            </span>
          </li>
          <li v-else class="nav-item d-flex align-items-center me-3 cursor-pointer" data-bs-toggle="tooltip"
            data-bs-placement="bottom" data-bs-title="No tienes conexión a internet">
            <span class="badge bg-gradient-secondary badge-md">
              <i class="fa-solid fa-wifi text-lg opacity-10"></i>
            </span>
          </li>
          <li class="nav-item d-flex align-items-center me-3 cursor-pointer" @click="showNewClient()"
            data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Configurar terminal">
            <div class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
              <i class="fa-solid fa-display text-white text-lg opacity-10"></i>
            </div>
          </li>
          <li class="nav-item d-flex align-items-center" data-bs-toggle="tooltip" data-bs-placement="bottom"
            data-bs-title="Ir a MI PERFIL">
            <router-link :to="{ name: 'Mi perfil' }" class="px-0 nav-link font-weight-bold text-white">
              <i class="fa fa-user" :class="isRTL ? 'ms-sm-2' : 'me-sm-2'"></i>
              <span class="d-sm-inline d-none">{{ dataUser ? dataUser.userFullName : '' }}</span>
            </router-link>
          </li>
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a href="#" @click="minimizeSidebar" class="p-0 nav-link text-white" id="iconNavbarSidenav">
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
              </div>
            </a>
          </li>
          <li class="px-3 nav-item d-flex align-items-center" data-bs-toggle="tooltip" data-bs-placement="bottom"
            data-bs-title="Configuraciones">
            <a class="p-0 nav-link text-white" @click="toggleConfigurator">
              <i class="cursor-pointer text-lg fa fa-cog fixed-plugin-button-nav"></i>
            </a>
          </li>
          <li v-if="showMessages" class="nav-item dropdown d-flex align-items-center pe-2" data-bs-toggle="tooltip"
            data-bs-placement="bottom" data-bs-title="Notificaciones">
            <a href="#" class="p-0 nav-link text-white" :class="[showMenu ? 'show' : '']" id="dropdownMenuButton"
              data-bs-toggle="dropdown" aria-expanded="false" @click="showMenu = !showMenu" @blur="closeMenu">
              <i class="cursor-pointer text-lg fa fa-bell"></i>
            </a>
            <ul class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4" :class="showMenu ? 'show' : ''"
              aria-labelledby="dropdownMenuButton">
              <li class="mb-2">
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <img src="../../assets/img/team-2.jpg" class="avatar avatar-sm me-3" alt="user image" />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">New message</span> from
                        Laur
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        13 minutes ago
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li class="mb-2">
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <img src="../../assets/img/small-logos/logo-spotify.svg"
                        class="avatar avatar-sm bg-gradient-dark me-3" alt="logo spotify" />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">New album</span> by
                        Travis Scott
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        1 day
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div class="my-auto avatar avatar-sm bg-gradient-secondary me-3">
                      <svg width="12px" height="12px" viewBox="0 0 43 36" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <title>credit-card</title>
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <g transform="translate(-2169.000000, -745.000000)" fill="#FFFFFF" fill-rule="nonzero">
                            <g transform="translate(1716.000000, 291.000000)">
                              <g transform="translate(453.000000, 454.000000)">
                                <path class="color-background"
                                  d="M43,10.7482083 L43,3.58333333 C43,1.60354167 41.3964583,0 39.4166667,0 L3.58333333,0 C1.60354167,0 0,1.60354167 0,3.58333333 L0,10.7482083 L43,10.7482083 Z"
                                  opacity="0.593633743" />
                                <path class="color-background"
                                  d="M0,16.125 L0,32.25 C0,34.2297917 1.60354167,35.8333333 3.58333333,35.8333333 L39.4166667,35.8333333 C41.3964583,35.8333333 43,34.2297917 43,32.25 L43,16.125 L0,16.125 Z M19.7083333,26.875 L7.16666667,26.875 L7.16666667,23.2916667 L19.7083333,23.2916667 L19.7083333,26.875 Z M35.8333333,26.875 L28.6666667,26.875 L28.6666667,23.2916667 L35.8333333,23.2916667 L35.8333333,26.875 Z" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        Payment successfully completed
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        2 days
                      </p>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li>
          <li class="px-2 nav-item d-flex align-items-center">
            <a class="p-0 cursor-pointer nav-link text-white" @click="logOut">
              <i class="fa-solid fa-right-from-bracket fixed-plugin-button-nav"></i>
              Cerrar sesión
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<style scoped>
.navbar-collapse {
  flex-grow: unset;
}

#logoHeaderSiscon {
  transition: transform .2s ease-out;
}

#logoHeaderSiscon:hover {
  transform: rotate(-10deg);
}

/* .conn-internet {
  height: 30px;
  width: 100%;
  padding: 2px;
} */
</style>
